import request from '@/api/request.js'; //请求对象

export function create(data) {
  return request({
    url: '/admin_soundsv2/create',
    method: 'post',
    data
  })
}
//获取音效列表
export function read(data) {
  return request({
    url: '/admin_soundsv2/read',
    method: 'post',
    data
  })
}

//设置音效分类
export function updateSoundMenu(data) {
  return request({
    url: '/admin_soundsv2/updateSoundMenu',
    method: 'post',
    data
  })
}

//设置音效
export function updateSound(data) {
  return request({
    url: '/admin_soundsv2/update',
    method: 'post',
    data
  })
}
//删除音效
export function removeSound(data) {
  return request({
    url: '/admin_soundsv2/remove',
    method: 'post',
    data
  })
}
